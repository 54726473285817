.login-box {
    padding: 30px;
    width: 80vw;
    max-width: 600px;

    button {
        outline: none;
        border: none;
        background-color: transparent;
        font-size: 1em;
        margin: 0.25em 1em;
        color: #006eff;
    }

    button:disabled {
        color: #939393;
    }
}

.login-box-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.5em;
    margin: 1em 0em;

    .banner {
        width: 250px;
    }
}

.option-box-header {
    font-weight: 600;
    font-size: 1.5em;
    margin: 1em 0em;
    text-align: center;
    align-self: center;
    display: inline;
    padding: 0.2em;
}

.option-box {
    text-align: center;
}

.input-area {
    margin: 10px 0px;
}

.input-row {
    padding: 0em 0.5em;
    border: 1px solid #ccc;
    border-top: none;
    height: 2.5em;
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
        width: 100%;
        border: none;
        background-color: transparent;
        font-size: 1em;
    }
}

.input-row:first-child {
    border-radius: 5px 5px 0px 0px;
    border: 1px solid #ccc;
}

.input-row:last-child {
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #ccc;
    border-top: none;
}

.input-row:first-child:last-child {
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #ccc;
}

#disclaimer-button {
    margin: 0px;
    padding: 0px;
}

dialog.disclaimer {
    text-align: center;
    border: none;
    border-radius: 10px;
    width: 70vw;
    height: 60vh;
    max-width: 1000px;
    filter: drop-shadow(0rem 0rem 1.5rem #7e7e7e);

    .tnc {
        text-align: left;
        flex-grow: 1;
        height: 85%;
        overflow: auto;
        margin-top: 10px;
    }

    .button-container {
        display: flex; 
        justify-content: space-evenly;
        .cancel {
            color: red
        }
    }
}

dialog::backdrop {
    background-color: #6e6e6e99;
}
