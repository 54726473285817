.title-left-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  .mode-select-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ebebeb;
    border-radius: 9px;
    padding: 3px;
  
    .mode-select-btn {
      background-color: transparent;
      font-weight: bold;
      border-radius: 6px;
      color: #707070;
      cursor: pointer;
      font-size: 0.9rem;
      padding: 0.5rem 0.9rem;
  
      &.selected {
        background-color: var(--company-identity-color);
        color: #fff;
      }
    }
  }
}


