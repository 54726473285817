@use "./animation.scss";

$banner-animation-duration: 2s;

.container {
  background-color: var(--background-color);

  .title-bar {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    border-bottom: 1px solid #dcdce4;

    >div {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid #efefef;
      font-weight: bolder;
    }

    .title-options-bar {
      >div {
        align-items: center;
        display: flex;
        min-height: 50px;
        margin: 0.25em 0.5em;
      }

      img {
        height: 1.5em;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    button {
      border-width: 0px;
      background: #fff;

      img {
        height: 1.5em;
      }
    }

    .banner {
      width: 300px;
      margin: 10px;
      border-top: none;
      justify-content: center;

      img {
        height: 12vw;
        max-height: 100px;
        min-height: 50px;
      }

      .rotating-banner {
        position: relative;
        background-color: white;
        bottom: 19px;
        right: 118px;
        width: 28px;

        -webkit-animation: rotating $banner-animation-duration ease-out;
        -moz-animation: rotating $banner-animation-duration ease-out;
        -ms-animation: rotating $banner-animation-duration ease-out;
        -o-animation: rotating $banner-animation-duration ease-out;
        animation: rotating $banner-animation-duration ease-out;
      }
    }

    .section-tabs {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      border: 0px;

      button {
        border: 0;
        background: #fff;
        font-size: 20px;
        margin: 8px 12px;

        &.selected {
          border-bottom: 2px solid var(--company-identity-color);
        }

        img {
          height: 1.5em;
        }
      }
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.expand {
  -webkit-animation: expand $banner-animation-duration;
  -moz-animation: expand $banner-animation-duration;
  -ms-animation: expand $banner-animation-duration;
  -o-animation: expand $banner-animation-duration;
  animation: expand $banner-animation-duration;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

.fade-in {
  -webkit-animation: fade-in $banner-animation-duration linear;
  -moz-animation: fade-in $banner-animation-duration linear;
  -ms-animation: fade-in $banner-animation-duration linear;
  -o-animation: fade-in $banner-animation-duration linear;
  animation: fade-in $banner-animation-duration linear;
}

.hidden {
  display: none;
}