@use "./animation.scss";

$banner-animation-duration: 2s;
$button-animation-duration: 0.5s;
$loading-animation-duration: 2.5s;

.chatroom-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    background-color: var(--background-color);

    .select-container {
        width: 200px;
        margin-left: 10px;
    }
    
    .product-container {
        padding: 10px 0;
        display: flex;
        align-items: center;
    
        img.filter-logo {
            height: 2em;
            margin-right: 3px;
            margin-left: 10px;
            filter: var(--svg-filter);
        }
    }

    .product-code-container {
        padding: 8px 10px;
        flex: auto;
    }

    .clean-context-btn-container {
        padding: 5px 10px 5px 5px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .clean-context-btn {
            min-width: 45px;
            border-radius: 200px;
            color: var(--company-identity-color);
            font-weight: 700;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
            height: 45px;
            // width: 3em;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--company-identity-color);
            background-color: var(--company-identity-color);
            padding: 0;
            // border-width: 0px;
            background: #fff;
            cursor: pointer;

            img {
                filter: var(--svg-filter);
            }
        
        }
    }
}

.chat-history-container {
    overflow: auto;
    font-size: 16px;
    flex: 1 1 0;
    flex-basis: 0px;
}

.center-items {
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.query-box {
    align-items: center;
    background-color: #fff;
    display: flex;
    flex: 1 0;
    // height: 3.5em;
    padding: 10px 10px;
    position: relative;
    border-top: 1px solid #dcdce4;

    input {
        flex-grow: 1;
        border-width: 0px;
        font-size: 14px;
        font-family: "Source Sans Pro", "Segoe UI", sans-serif;
        height: 100%;
    }
    button {
        border-width: 0px;
        background: #fff;
        cursor: pointer;
    }
    button:disabled {
        opacity: 40%;
        cursor: default;
    }
    img {
        height: 29px;
        width: 29px;
    }

    .loading-icon {
        -webkit-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
        -moz-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
        -ms-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
        -o-animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
        animation: rotating-no-delay $loading-animation-duration 0s linear infinite;
    }
      
    @keyframes rotating-no-delay {
        100% {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}

.new-topic-button {
    color: white;
    cursor: pointer;
    height: 100%;
    background-color: #677F91;
    color: white;
    margin: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    text-align: center;
    opacity: 70%;
    width: 60px;
    white-space: nowrap;
    font-size: 40px;

}
.new-topic-text{
    position: relative;
    bottom: 4px;
}
.expanded {
    -webkit-animation: expand-button $button-animation-duration ease-out;
    -moz-animation: expand-button $button-animation-duration ease-out;
    -ms-animation: expand-button $button-animation-duration ease-out;
    -o-animation: expand-button $button-animation-duration ease-out;
    animation: expand-button $button-animation-duration ease-out;
    width: 120px;
    font-size: 16px;
    .new-topic-text{
        position: relative;
        bottom: 0px;
    }
}

.collapsed {
    -webkit-animation: collapse-button $button-animation-duration ease-out;
    -moz-animation: collapse-button $button-animation-duration ease-out;
    -ms-animation: collapse-button $button-animation-duration ease-out;
    -o-animation: collapse-button $button-animation-duration ease-out;
    animation: collapse-button $button-animation-duration ease-out;
    width: 60px;
}

.query-bar {
    display: flex;
}

footer {
    font-size: 0.7em;
    text-align: center;
    padding: 1em 1em;
}

dialog.export {
    text-align: center;
    border: none;
    border-radius: 10px;
    width: 50vw;
    max-width: 300px;
    height: 200px;
    div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 1em 0em;
    }

    h3 {
        margin-bottom: 25px;
    }

    label {
        display: block;
        width: 3em;
    }

    input {
        display: block;
        border: none;
        border-bottom: 1.5px solid transparent;
    }
    
    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 20px;
    }

    button {
        display: block;
        font-size: 1em;
        margin: 0.25em 0.25em;
        border-radius: 5px;
        outline: none;
        width: 40%;
        padding: 4px;
    }

    button.cancel {
        background-color: transparent;
        color: #939393;
        border: 1px solid #939393;
    }

    button.download {
        background-color: #006eff;
        color: white;
        font-weight: bold;
        border: none;
    }

    .selected {
        border: none;
        border-bottom: 1.5px solid #000;
        // text-decoration: underline;
    }

}

.header-accent {
    min-height: 15px;
    background-color: var(--company-identity-color);
    width: 100%;
    margin: auto;
    z-index: 2;
}

.header-accent-white {
    min-height: 15px;
    background-color: #fff;
    width: 100%;
    margin-top: -15px;
    z-index: 1;
}

.expand {
    -webkit-animation: expand $banner-animation-duration;
    -moz-animation: expand $banner-animation-duration;
    -ms-animation: expand $banner-animation-duration;
    -o-animation: expand $banner-animation-duration;
    animation: expand $banner-animation-duration;
    transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

.fade-in {
    -webkit-animation: fade-in $banner-animation-duration linear;
    -moz-animation: fade-in $banner-animation-duration linear;
    -ms-animation: fade-in $banner-animation-duration linear;
    -o-animation: fade-in $banner-animation-duration linear;
    animation: fade-in $banner-animation-duration linear;
}


.party-mode {
    animation: colors 10s ease-in-out infinite
}