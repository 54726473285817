.compare-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
  height: 6em;
  gap: 20px;

  .product-select-group {
    display: flex;
    gap: 25px;
    align-items: center;

    .toolbar-header {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
    }

    .product-select-container {
      position: relative;
      min-width: 180px;
      max-width: 250px;

      .delete-product-btn {
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 200px;
        border: 1px solid red;
        background-color: white;
        color: red;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
        width: 1.7em;
        height: 1.7em;
      }
    }
  }

  .btn-group {
    display: flex;
    gap: 15px;
    align-items: center;
    height: 100%;

    .add-product-btn {
      min-width: 80px;
      border: 1px solid var(--company-identity-color);
      border-radius: 8px;
      background-color: white;
      color: var(--company-identity-color);
      font-weight: 700;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
      // height: 3em;
      height: 100%;
      width: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .add-icon {
      height: 1.7em;
      width: 1.7em;
    }

    .add-icon {
      filter: var(--svg-filter);
    }
  }
}
